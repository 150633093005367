<template>
  <ez-content class="contacts-mapping">
    <ez-container>
      <ez-row>
        <ez-col>
          <ez-box>
            <template slot="body">
              <div class="block__content">
                <ez-form>
                  <div class="mt-2 mb-4" v-if="isMandatoryError && importWasClicked">
                    <ez-notification status="error" type="fluid" :isCloseable="false">
                      <template
                        slot="title"
                      >{{ $t('import-mapping.mandatory-fields-notification') }}</template>
                    </ez-notification>
                  </div>
                  <ez-form-group v-for="item in items" :key="`mapping-item-${item.key}`">
                    <ez-row gutter="20" justify="between">
                      <ez-col>
                        <ez-form-item :label="$t('import-mapping.mapping-select-label')">
                          <ez-select
                            :placeholder="$t('import-mapping.mapping-select-placeholder')"
                            :options="options"
                            v-model="item.mapping"
                            item-label="name"
                            track-by="id"
                            :is-single="true"
                          />
                        </ez-form-item>
                      </ez-col>
                      <ez-col>
                        <ez-form-item :label="$t('import-mapping.example-input-label')">
                          <ez-input :value="item.value" :readonly="true"/>
                        </ez-form-item>
                      </ez-col>
                    </ez-row>
                  </ez-form-group>
                </ez-form>
              </div>

              <ez-box class="box--inner">
                <template slot="header">{{ $t('import-mapping.add-tags-header' )}}</template>
                <template slot="body">
                  <ez-select
                    v-model="tags"
                    :multiple="true"
                    :label="$t('import-mapping.add-tags-select-label')"
                    :taggable="true"
                    :options="tagsOptions"
                    item-label="name"
                    track-by="id"
                    @remove="handleRemoveTag"
                    @tag="handleNewTag"
                  />
                </template>
              </ez-box>

              <div class="block__buttons">
                <ez-button-group justify="end">
                  <ez-button
                    type="secondary"
                    @click="onCancel">
                    {{ $t('cancel-button' )}}
                  </ez-button>
                  <ez-button
                    type="primary"
                    @click="onUploadFileAgain"
                  >{{ $t('import-mapping.upload-again-button' )}}</ez-button>
                  <ez-button
                    type="primary"
                    @click="onProcessImport"
                    :preloader="importing"
                  >{{ $t('import-mapping.import-button' )}}</ez-button>
                </ez-button-group>
              </div>

              <div class="contacts-mapping">
                <ez-dialog :open="importing" size="small">
                  <template slot="header">
                    <ez-icon name="download" color="#555555" size="20"/>Import
                  </template>
                  <template>
                    <ez-preloader :value="true" :stop="false"></ez-preloader>
                    <div class="text">Import is in progress. Please wait.</div>
                  </template>
                </ez-dialog>

                <ez-dialog :open="statusOpen2" size="small">
                  <template slot="header">
                    <ez-icon name="download" color="#555555" size="20"/>Import
                  </template>
                  <template>
                    <div class="success">
                      <ez-icon name="check" color="#00ADAB" size="60"></ez-icon>
                    </div>
                    <div class="text">Import was successful.</div>
                  </template>
                </ez-dialog>

                <ez-dialog :open="error != null" size="small" @close="error = null">
                  <template slot="header">
                    <ez-icon name="download" color="#555555" size="20"/>Import
                  </template>
                  <template>
                    <div class="error">
                      <ez-icon name="times" color="#00ADAB" size="50"></ez-icon>
                    </div>
                    <div class="text">Error!
                      <div>Error occured during import. Try to retry.</div>
                    </div>
                  </template>
                </ez-dialog>
              </div>
            </template>
          </ez-box>
        </ez-col>
      </ez-row>
    </ez-container>
  </ez-content>
</template>

<script>
import get from 'lodash/get';
import * as navActions from '../../store/nav/actions';
import * as tagsActions from '../../store/tags/actions';
import * as tagsGetters from '../../store/tags/getters';
import * as authGetters from '../../store/auth/getters';
import * as injectNames from '../../services/names';
import importHelper, { status as importStatus } from './import';

const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export default {
  name: 'AppImportMapping',
  dependencies: [
    injectNames.CONTACTS_API,
    injectNames.TAGS_API,
    '$timeout',
    '$log',
  ],
  data() {
    return {
      tags: [],
      importItem: null,
      options: [],
      items: [],
      importing: false,
      error: null,
      success: null,
      mandatoryMapping: ['email'],
      importWasClicked: false,

      // temp data
      ignored1: '',
      ignored2: '',
      ignored3: '',
      ignored4: '',
      statusOpen1: false,
      statusOpen2: false,
      statusOpen3: false,
      statusOpen4: false,
      progress1: 50,
      progress2: 100,
      count: 1,
    };
  },
  created() {
    this.setBreadCrumbs();
    this.loadTags();
    this.loadItem();

    this.options = importHelper(this.$t.bind(this));
  },
  beforeDestroy() {
    // close all dialogs. preventing scroll bug.
    this.importing = false;
    this.error = null;
    this.success = null;
  },
  computed: {
    currentUserId() {
      const user = this.$store.getters[`auth/${authGetters.CURRENT_USER}`];
      return user ? user.legacyId : undefined;
    },
    tagsOptions() {
      const items = this.$store.getters[`tags/${tagsGetters.ITEMS}`];
      return items;
    },
    importId() {
      return this.$route.params.importId;
    },
    mapping() {
      return this.items
        .filter((item) => item.mapping != null && item.mapping !== 'ignore-this-value')
        .reduce((result, item) => ({
          ...result,
          [item.key]: item.mapping,
        }), {});
    },
    tagsIds() {
      return this.tags.map((item) => item.id);
    },

    isMandatoryError() {
      const checkFields = this.mandatoryMapping.map((mappingKey) => ({
        id: mappingKey,
        valid: this.items.find((item) => item.mapping === mappingKey) != null,
      }));
      const invalidFields = checkFields.filter((item) => !item.valid);
      return invalidFields.length > 0 && this.items.length > 0;
    },

    isValid() {
      return !this.isMandatoryError && this.items.length > 0;
    },
  },
  methods: {
    setBreadCrumbs() {
      this.$store.dispatch(`nav/${navActions.SET_BREADCRUMBS}`, [
        {
          name: this.$t('app-name'),
          status: '',
          href: '/',
        },
        {
          name: this.$t('import-new.title'),
          status: '',
          href: '/new-import',
        },
        {
          name: this.$t('import-mapping.title'),
        },
      ]);
      window.document.title = `${this.$t('app-public-title')} - ${this.$t(
        'import-mapping.title',
      )}`;
    },
    onCancel() {
      this.$router.push({ name: 'dashboard' });
    },
    onUploadFileAgain() {
      this.$router.push({ name: 'new-import' });
    },
    onMappingUpdate($event, item) {
      item.mapping = $event;
    },

    // tags
    loadTags() {
      this.$store.dispatch(`tags/${tagsActions.GET_ITEMS}`);
    },

    handleRemoveTag(tag) {
      const newTags = this.tags.filter((item) => item.id !== tag.id);
      this.tags = newTags;
    },

    handleNewTag(tag) {
      const api = this[injectNames.TAGS_API];
      const data = {
        name: tag,
        user: this.currentUserId,
      };
      api
        .createTag(data)
        .then((item) => {
          // append created tag item to tags store
          this.$store.dispatch(`tags/${tagsActions.APPEND_ITEM}`, item);
          // apend item to tags values
          this.tags.push(item);
        })
        .catch((err) => {
          this.$log({ err });
        });
    },

    // load item info
    async loadItem() {
      const api = this[injectNames.CONTACTS_API];
      try {
        this.importItem = await api.checkImport(this.importId);
        const metaData = JSON.parse(
          get(this.importItem, 'attributes.meta', '{}'),
        );
        const row = metaData.row || {};
        this.items = Object.keys(row).map((key) => {
          const value = row[key];
          const isEmail = validateEmail(value);
          return {
            key,
            value,
            mapping: isEmail ? 'email' : null,
          };
        });
      } catch (err) {
        this.$log({ err });
      }
    },

    async checkItemStatus() {
      const api = this[injectNames.CONTACTS_API];
      try {
        const item = await api.checkImport(this.importId);
        if (item != null) {
          const status = get(
            item,
            'attributes.status',
            importStatus.IMPORT_STATUS_NEW,
          );
          this.count += 1;
          if (
            this.count <= 25
            && status === importStatus.IMPORT_STATUS_MAPPING
          ) {
            this.$timeout(() => {
              this.checkItemStatus();
            }, 5000);
          } else if (status === importStatus.IMPORT_STATUS_COMPLETE) {
            this.importing = false;
            this.error = null;
            this.$notify({
              data: {
                type: 'success',
                content: this.$t('import-mapping.success-message'),
              },
            });
            setTimeout(() => {
              this.$router.push({ name: 'dashboard' });
            }, 250);
          } else if (status === importStatus.IMPORT_STATUS_ERROR) {
            this.importing = false;
            this.error = 'Something wrong happened :(';
            this.$notify({
              data: {
                type: 'error',
                content: this.$t('import-mapping.error-message'),
              },
            });
          }
        }
      } catch (err) {
        this.$log({ err });
      }
    },

    async onProcessImport() {
      this.importWasClicked = true;
      if (this.importing || !this.isValid) {
        return;
      }
      const api = this[injectNames.CONTACTS_API];
      try {
        this.importing = true;
        this.error = null;
        await api.processImport(this.importId, this.mapping, this.tagsIds);
        this.$timeout(() => {
          this.checkItemStatus();
        }, 5000);
      } catch (err) {
        this.importing = false;
        this.error = err;
        this.$log({ err });
      }
    },
  },
};
</script>
