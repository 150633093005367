class ImportFieldOption {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

export const status = {
  IMPORT_STATUS_NEW: 1,
  IMPORT_STATUS_MAPPING: 2,
  IMPORT_STATUS_COMPLETE: 3,
  IMPORT_STATUS_ERROR: 4,
};

export default ($t) => {
  const firstName = new ImportFieldOption(
    'first-name',
    $t('contact-fields.first-name'),
  );
  const lastName = new ImportFieldOption(
    'last-name',
    $t('contact-fields.last-name'),
  );
  const email = new ImportFieldOption('email', $t('contact-fields.e-mail'));
  const country = new ImportFieldOption(
    'country',
    $t('contact-fields.country'),
  );
  const city = new ImportFieldOption('city', $t('contact-fields.city'));
  const addr = new ImportFieldOption('addr', $t('contact-fields.address'));
  const state = new ImportFieldOption('state', $t('contact-fields.state'));
  const zip = new ImportFieldOption('zip', $t('contact-fields.zip'));
  const phone = new ImportFieldOption('phone', $t('contact-fields.phone'));
  const createDate = new ImportFieldOption(
    'dt-create',
    $t('contact-fields.created-at'),
  );
  const type = new ImportFieldOption(
    'type',
    $t('contact-fields.type'),
  );
  const ignoreValue = new ImportFieldOption(
    'ignore-this-value',
    $t('contact-fields.ignore-this-value'),
  );

  const options = [
    ignoreValue,
    firstName,
    lastName,
    email,
    country,
    city,
    addr,
    state,
    zip,
    phone,
    createDate,
    type,
  ];

  return options;
};
